import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  SassyText,
  Section,
  SectionWrapper,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, QuoteModalPlaceholder, HeroSectionData, HeroSectionContainer, HeroImgContainer, ModalContainer, HeroSectionHeading } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ExtraBenefits"))
const ProtectAgainstRisks = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/ProtectAgainstRisks"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/ReviewSection"))
const FooterSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google5/FooterSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();

  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()
  
  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const heroSectionDefaultComp = (
    <>
      <StyledHeroSectionHeading>
        We have a plan to secure your business’s future
      </StyledHeroSectionHeading>
      <StyledHeroImgContainer>
        <StaticImage 
          src="../../assets/images/type-of-business-pages/storage.webp"
          alt="secure your business"
          width={468}
          height={295}
          loading="eager"
          placeholder="none"
        />
      </StyledHeroImgContainer>
    </>
  )

  return (
    <>
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick} 
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <StyledHeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </HeroSectionContainer>
              <StyledModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen} 
                />
              </StyledModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness style={{background: "#fff"}} /> : null}
      <ExtraBenefits />
      <ProtectAgainstRisks desktopStyles={{marginTop: "0"}} mobileStyles={{marginTop: "3rem"}} style={{background: "#fff"}} />
      <QuotesFromCompanies sectionBg="#fff" title={<SassyText>Get Quotes from over 10+ Reputed Insurers</SassyText>} titleStyle={{textTransform: "uppercase"}} mobileStyles={{}} desktopStyles={{}} />
      <ReviewSection />
      <FooterSection handleGetQuoteClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

const StyledHeroBg = styled(HeroBg)`
  background: #F4F4F8;
`

const StyledHeroImgContainer = styled(HeroImgContainer)`
  width: min(468px, 100%);
  @media screen and (max-width: 768px) {
    width: min(320px, 100%);
    margin: auto;
    /* margin-top: 1rem; */
  }
`

const StyledModalContainer = styled(ModalContainer)`
  width: min(520px, 100%);
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  font-size: 36px;
  line-height: 50px;
  color: #2A2B7F;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export default IndexPage;
